import React, { useState, useEffect } from "react";
import { _get } from "../utils/services/api.service";
import axios from "axios";
import Layout from "../components/Layout/Layout";
import styled from "@emotion/styled";
import { version } from "../../static/version.json";

const Wrapper = styled("div")`
    padding: 29px;
  `,
  Thead = styled("thead")`
    background-color: #ccc;
  `,
  Tdata = styled("td")`
    padding: 9px;
    border: solid 1px;
  `;
const VersionPage = () => {
  const [loading, setLoading] = useState(true),
    defaultVersion = {
      version: "Loading",
      date: "Loading",
    },
    [mainApiVersion, setMainApiVersion] = useState(defaultVersion),
    [novoedApiVersion, setNovoedApiVersion] = useState(defaultVersion),
    [IDPVersion, setIDPVersion] = useState(defaultVersion),
    { AA_VERSION, AA_RELEASE_DATE } = version[version.length - 1];

  useEffect(() => {
    const getApiVersion = async () => {
      const apiVersionResp = await _get({
        endpoint: "health/version?latest=true",
        isPublic: true,
      });
      if (apiVersionResp.success) {
        const apiVersion = apiVersionResp.data.version,
          { AA_VERSION: version, AA_RELEASE_DATE: date } = apiVersion;

        setMainApiVersion({ version, date });
      } else {
        setMainApiVersion(defaultVersion);
      }
      axios
        .get(
          `${process.env.GATSBY_novoed_connector_endpoint}version?latest=true`,
          {
            withCredential: true,
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": process.env.GATSBY_novoed_connector_apiKey,
            },
          }
        )
        .then(res => {
          const apiVersion = res.data.response.version,
            { AA_VERSION: version, AA_RELEASE_DATE: date } = apiVersion;
          setNovoedApiVersion({ version, date });
        })
        .catch(error => {
          setNovoedApiVersion(defaultVersion);
        });
      axios
        .get(
          `${process.env.GATSBY_idp_connector_endpoint}version.php?latest=true`
        )
        .then(res => {
          const apiVersion = res.data.version,
            { AA_VERSION: version, AA_RELEASE_DATE: date } = apiVersion;
          setIDPVersion({ version, date });
        })
        .catch(error => {
          setIDPVersion(defaultVersion);
        });
      setLoading(false);
    };
    getApiVersion();
  }, []);
  return (
    <Layout loading={loading}>
      <Wrapper>
        <table>
          <Thead>
            <tr>
              <Tdata id="v-name">MICROSERVICE</Tdata>
              <Tdata id="v-version">CURRENT VERSION</Tdata>
              <Tdata id="v-date">RELEASE DATE</Tdata>
            </tr>
          </Thead>
          <tbody>
            <tr id="react-v">
              <Tdata id="react-v-name-v">REACT APP</Tdata>
              <Tdata id="react-v-version-v">{AA_VERSION}</Tdata>
              <Tdata id="react-v-date-v">{AA_RELEASE_DATE}</Tdata>
            </tr>
            <tr id="node-v">
              <Tdata id="node-v-name-v">NODE MA APP</Tdata>
              <Tdata id="node-v-version-v">{mainApiVersion.version}</Tdata>
              <Tdata id="node-v-date-v">{mainApiVersion.date}</Tdata>
            </tr>
            <tr id="novoed_v">
              <Tdata id="novoed-v-name-v">NOVOED CONNECTOR</Tdata>
              <Tdata id="novoed-v-version-v">{novoedApiVersion.version}</Tdata>
              <Tdata id="novoed-v-date-v">{novoedApiVersion.date}</Tdata>
            </tr>
            <tr id="IDP_v">
              <Tdata id="IDP-v-name-v">IDENTITY PROVIDER</Tdata>
              <Tdata id="IDP-v-version-v">{IDPVersion.version}</Tdata>
              <Tdata id="IDP-v-date-v">{IDPVersion.date}</Tdata>
            </tr>
          </tbody>
        </table>
      </Wrapper>
    </Layout>
  );
};

export default VersionPage;
